import Cookies from 'js-cookie'
import store from '@/store/index'

const TokenKey = 'isLogged'
const Token = 'token'
const User = 'user'

// Function to set cookies in subdomain
const setCookieInSubdomain = (cookieName, value) => {
  const domain = window.location.hostname
  Cookies.set(cookieName, value, { domain })
}

// Function to get cookies in subdomainp
const getCookieInSubdomain = cookieName => {
  const domain = window.location.hostname
  return Cookies.get(cookieName, { domain }) || false
}

// Function to remove cookies in subdomain
const removeCookieInSubdomain = cookieName => {
  const domain = window.location.hostname
  Cookies.remove(cookieName, { domain })
}

// Determine home route based on user role
export const getHomeRouteForLoggedInUser = userRole => {
  switch (userRole) {
    case 'student':
      return { name: 'student-profile' }
    default:
      return { name: 'user-profile' }
  }
}

// Check if user is logged in
export const isLogged = () => getCookieInSubdomain(TokenKey) === '1'

// Set logged status
export const setLogged = key => setCookieInSubdomain(TokenKey, key)

// Remove token
export const removeToken = () => removeCookieInSubdomain(TokenKey)

// Set token
export const setToken = token => setCookieInSubdomain(Token, token)

// Get token
export const getToken = () => getCookieInSubdomain(Token) || false

// Delete token
export const deleteToken = () => removeCookieInSubdomain(Token)

// Authenticate user
export const Auth = user => setCookieInSubdomain(User, user)

// Get authenticated user
export const getAuth = () => {
  const user = getCookieInSubdomain(User)
  return user ? JSON.parse(user) : false
}

// Remove authenticated user
export const removeAuth = () => removeCookieInSubdomain(User)

// Check if user lacks permissions
export const notHasPermissions = () => getCookieInSubdomain(TokenKey) === '2'

// Reset permissions
export const resetPermission = () => {
  store.commit('roles/SET_AUTH_USER_PERMISSIONS', [])
  store.commit('roles/SET_AUTH_USER_ROLES_PERMISSIONS', [])
}

// Check if user is logged in and authenticated
export const isUserLoggedIn = () => !!(isLogged() && getToken() && getAuth())

// Get user data
export const getUserData = () => getAuth()
