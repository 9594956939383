import Cookies from 'js-cookie'
import store from '@/store'

const TokenKey = 'isLogged'
const Token = 'token'
const User = 'user'

/**
 * Sets cookie in subdomain
 * @param {string} cookieName
 * @param {string} value
 * @returns {boolean}
 */
export function setCookieInSubdomain(cookieName, value) {
  const domain = window.location.hostname
  return Cookies.set(cookieName, value, { domain })
}

/**
 * Gets cookie in subdomain
 * @param {string} cookieName
 * @returns {string|boolean}
 */
export function getCookieInSubdomain(cookieName) {
  const domain = window.location.hostname
  const cookie = Cookies.get(cookieName, { domain })
  if (cookie) {
    return cookie
  }
  return false
}

/**
 * Removes cookie in subdomain
 * @param {string} cookieName
 * @returns {boolean}
 */
export function removeCookieInSubdomain(cookieName) {
  const domain = window.location.hostname
  return Cookies.remove(cookieName, { domain })
}

/**
 * Gets user's location
 */
export function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      const Latitude = position.coords.latitude
      const Longitude = position.coords.longitude
    })
  }
}

/**
 * Shows user's location
 * @param {object} position
 */
function showPosition(position) {
  const Latitude = position.coords.latitude
  const Longitude = position.coords.longitude
}

/**
 * Checks if user is logged in
 * @returns {boolean}
 */
export function isLogged() {
  const token = getCookieInSubdomain(TokenKey)
  if (token === '1') return true
  return false
}

/**
 * Checks if user lacks permissions
 * @returns {boolean}
 */
export function notHasPermissions() {
  return getCookieInSubdomain(TokenKey) === '2'
}

/**
 * Sets logged status
 * @param {string} key
 * @returns {boolean}
 */
export function setLogged(key) {
  return setCookieInSubdomain(TokenKey, key)
}

/**
 * Removes token
 * @returns {boolean}
 */
export function removeToken() {
  return removeCookieInSubdomain(TokenKey)
}

/**
 * Sets token
 * @param {string} token
 * @returns {boolean}
 */
export function setToken(token) {
  return setCookieInSubdomain(Token, token)
}

/**
 * Gets token
 * @returns {string|boolean}
 */
export function getToken() {
  const token = getCookieInSubdomain(Token)
  return token || false
}

/**
 * Deletes token
 * @returns {boolean}
 */
export function deleteToken() {
  return removeCookieInSubdomain(Token)
}

/**
 * Authenticates user
 * @param {object} user
 * @returns {boolean}
 */
export function Auth(user) {
  return setCookieInSubdomain(User, JSON.stringify(user))
}

/**
 * Gets authenticated user
 * @returns {object|boolean}
 */
export function getAuth() {
  if (getCookieInSubdomain(User)) {
    return getCookieInSubdomain(User)
  }
  return false
}

/**
 * Removes authenticated user
 * @returns {boolean}
 */
export function removeAuth() {
  return removeCookieInSubdomain(User)
}

/**
 * Resets user's permissions
 */
export function resetPermission() {
  store.commit('roles/SET_AUTH_USER_PERMISSIONS', [])
  store.commit('roles/SET_AUTH_USER_ROLES_PERMISSIONS', [])
}

/**
 * Checks if user is logged in and authenticated
 * @returns {boolean}
 */
export const isUserLoggedIn = () => !!(isLogged() && getToken() && getAuth())
